import './App.css';
import React from "react";
import {Navblock} from "./pages/Nav.js";


function App() {
  return (
    <div className= "App" >
        <Navblock/>
        
    </div>
  );
}

export default App;
