import * as React from "react";
import { Routes, Route} from "react-router-dom";
import About from "./About.js";
import Contact from "./Contact.js";
import Projects from "./Projects";
import Skills from "./Skills.js";
import App from "../App.js";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

export function NavRoutes() {
    return(
        
            <Routes>
                <Route path="/" element={<App/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/skills" element={<Skills/>} />
                <Route path="/projects" element={<Projects/>} />
            </Routes>
    );
}

export function Navblock() {
    return(
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand>Jeremiah Garcia</Navbar.Brand>
                <Nav defaultActiveKey="/about" className="me-auto">
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/skills">Skills</Nav.Link>
                    <Nav.Link href="/projects">Projects</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
        
    );
}
