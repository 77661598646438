import React from "react";
import Card from "react-bootstrap/Card";

export default function Bodycards(props) {
    return(
    <Card style={{ width: '25rem', height: '15rem', marginLeft: '3rem', marginTop: '3rem' }}>
      <Card.Header>{props.soc.title}</Card.Header>
      <Card.Body>
        <Card.Text>
          <a href={props.soc.link}>{props.soc.title}</a>
        </Card.Text>
        <Card.Text>
          <p style={{borderStyle:"solid", borderRadius:"0.375rem", position:"relative", height:"8rem", padding:"8px" }}>{props.soc.txt}</p>
        </Card.Text>
        <Card.Img />
      </Card.Body>
    </Card>
    );

}
