import * as React from "react";
import {Navblock} from "./Nav";
import Bodycards from "../components/Bodycards.js"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


export default function About() {

    var socialArray = [ 
        {"title":"LinkedIn", "link":"https://www.linkedin.com/in/jeremiah-garcia-b35552219", 
        "txt":"Includes certifications from IBM SkillsBuild(Cybersecurity) and Coursera(React). "},


        {"title":"Github", "link":"https://github.com/Jercia3", 
        "txt":"There are a few web scraping projects. Working on my Github game."}
    ]

    return(
        <div id="about" style={{backgroundColor:"lightgrey"}}>
            <Navblock/>
            <Row xs={1} md = {1} style={{width:"50%", display:"inline-block"}} >
                {socialArray.map((_, idx)=> (
                <Col>
                        <Bodycards soc = {socialArray[idx]}/>           
                </Col>
                ))}
            </Row>
            <div style={
                {
                float:"right",
                marginTop:"3rem",
                marginRight:"3rem",
                height:"34rem",
                width:"28rem",
                borderRadius:"0.375rem"
            }}>
            <iframe
            title= "title"
            style={{
            position: "relative",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "0.375rem"
            }}
            src={`https://docs.google.com/document/d/1N_zqgOknz6rL0jOHJKlymFpsvvfXMV7I/preview`}
            frameBorder="0"
            />
            </div>
        </div>
    );

}
